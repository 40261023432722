import DOMPurify from 'isomorphic-dompurify'
import linkifyHtml from 'linkify-html'

/**
 * Linkify a string of content.
 *
 * @param content - The content to linkify.
 * @returns A string for use with `v-html`.
 */
export default function useLinkify(content = '') {
  return DOMPurify.sanitize(
    linkifyHtml(content.replaceAll('\n', '<br />'), {
      target: '__blank',
      rel: 'noopener noreferrer',
    }),
    {
      USE_PROFILES: { html: true },
      ADD_ATTR: ['target'],
    },
  )
}
